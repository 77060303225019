<template>
    <main id="container" data-name="main">
        <!-- 메인비주얼 -->
        <section id="visual" class="section">
            <div class="swiper-container swiper-visual" >
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <div class="txt-area">
                            <p class="headline txt1">대한민국 금융상품판매 전문 플랫폼</p>
                            <p class="headline txt2">피플라이프</p>
                            <p class="headline txt3">
                                고객분들이 언제나 원하는 금융을 만날수 있도록<br>
                                피플라이프만의 전문화된 금융 솔루션으로<br>
                                여러분의 일생을 보다 풍요롭게 지켜드립니다.
                            </p>
                        </div>
                        <div class="bg" style="background-image:url('/img/main/visual_bg_slide1.jpg')"></div>
                    </div>
                    <div class="swiper-slide">
                        <div class="txt-area">
                            <p class="headline txt1">금융업계 혁신의 아이콘,</p>
                            <p class="headline txt2">피플라이프</p>
                            <p class="headline txt3">
                                고객내방형 보험샵 ‘보험클리닉’<br>
                                중소기업 리스크 관리 컨설팅 브랜드 ‘CEO 클리닉’<br>
                                ‘찾아가는 보험클리닉’ 서비스 신설
                            </p>
                        </div>
                        <div class="bg" style="background-image:url('/img/main/visual_bg_slide2.jpg')"></div>
                    </div>
                    <div class="swiper-slide">
                        <div class="txt-area">
                            <p class="headline txt1">믿을 수 있는</p>
                            <p class="headline txt2">피플라이프</p>
                            <p class="headline txt3">고객님의 성원에 감사드리며, 더 큰 신뢰와 믿음직한 서비스로 보답하겠습니다.</p>
                            <div class="btn_area">
                                <button class="brandBtn btn_more" type="button" data-target="#brand_pop"><span>자세히보기</span><i class="long-arrow-right"></i></button>
                            </div>
                        </div>
                        <div class="bg" style="background-image:url('/img/main/visual_bg_slide3.jpg')"></div>
                    </div>
                </div>
                <!-- Add Pagination -->	
                <div class="swiper-nav">
                    <div class="number"><span class="count"></span><span>/</span><span class="total"></span></div>
                    <div class="swiper-pagination"></div>
                </div>
            </div>
            <div class="group_visual_news">
                <h4 class="tit_news">NEWS</h4>
                <div class="news_wrap">
                    <ul class="list_news">
                        <li class="item_news" data-attr-top="topView" data-attr-id="/id">
                            <span class="tit" data-text="/title" data-clamp="1"></span>
                        </li>
                    </ul>
                </div>
                <div class="news_btn">
                    <!-- If we need navigation buttons -->
                    <div class="swiper-button-prev newBtn"></div>
                    <div class="swiper-button-next newBtn"></div>
                </div>
            </div>
        </section>
        <!-- //메인비주얼 -->

        <!-- 비즈니스 -->
        <section class="section section_business" id="business">
            <div class="section_wrap clearfix">
                <div class="tab_menu float-left">
                    <div class="tab_btn corp"><button> <!-- 법인컨설팅 --></button></div>
                    <div class="tab_btn fa"><button>  <!-- 개인영업(FA) --></button></div>
                    <div class="tab_btn bohum"><button> <!-- 보험클리닉 --></button></div>
                    <div class="tab_btn tfa"><button> <!-- TM영업(TFA) --></button></div>
                </div>
                <div class="tab_cont float-left">

                    <!-- 법인컨설팅 -->
                    <div class="tab_item">
                        <div class="tab_inner">
                            <div class="desc">
                                <h2 class="tit_section">OUR <strong>BUS<span>INESS</span></strong></h2>
                                <h4>법인컨설팅</h4>
                                <p class="sub_title">
                                    중소기업, 개인사업자를 위한 <br>
                                    지속 가능한 경영컨설팅
                                </p>
                                <p>
                                    리스크관리부터 컨설팅 후 사후관리까지,  <br>
                                    법인컨설팅 명가 피플라이프의 노하우가 집약된 법인영업채널이  <br>
                                    중소기업 위기극복 파트너로써 고민을 해결해드립니다.  <br>
                                </p>
                                <a href="/sales/Corporation" class="btn_more"><span>서비스 자세히보기</span><i class="long-arrow-right"></i></a>
                                <a href="/recruit/RecruitCorp" class="btn_more"><span>법인컨설팅 채용보기</span><i class="long-arrow-right"></i></a>
                            </div>
                            <!-- If we need pagination -->
                            <div class="swiper-pagination"></div>	
                        </div>
                        <div class="category teb_two swiper-container">
                            <ul class="cate_cont swiper-wrapper">
                                <li class="cate_item swiper-slide" data-titSection-color="color-white">
                                    <div class="itemimg">
                                        <img src="/img/main/business_teb2_01.jpg" alt="경영 리스크 관리">
                                    </div>
                                </li>
                                <li class="cate_item swiper-slide" data-titSection-color="color-black">
                                    <div class="itemimg">
                                        <img src="/img/main/business_teb2_02.jpg" alt="재무구조 분석">
                                    </div>
                                </li>
                                <li class="cate_item swiper-slide" data-titSection-color="color-black">
                                    <div class="itemimg">
                                        <img src="/img/main/business_teb2_03.jpg" alt="가업승계 플랜">
                                    </div>
                                </li>
                                <li class="cate_item swiper-slide" data-titSection-color="color-white">
                                    <div class="itemimg">
                                        <img src="/img/main/business_teb2_04.jpg" alt="경영 효율화 전략">
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- //법인컨설팅 -->

                    <!-- 개인영업(FA) -->
                    <div class="tab_item">
                        <div class="tab_inner">
                            <div class="desc">
                                <h2 class="tit_section">OUR <strong>BUS<span>INESS</span></strong></h2>
                                <h4>개인영업(FA)</h4>
                                <p class="sub_title">
                                    개인고객대상<br>
                                    금융상품 비교분석・재무설계 서비스<br>
                                </p>
                                <p>
                                    보장분석 전문 시스템인 어시스트(@ssist)를 통해<br>
                                    고객의 보장에 대한 유지/조정/해지를 분석하고<br>
                                    최적의 상품을 비교하여 포트폴리오를 제공해 드립니다.
                                </p>
                                <a href="/sales/Fa" class="btn_more"><span>서비스 자세히보기</span><i class="long-arrow-right"></i></a>
                                <a href="/recruit/RecruitFa" class="btn_more"><span>개인영업 채용보기</span><i class="long-arrow-right"></i></a>
                            </div>
                            <!-- If we need pagination -->
                            <div class="swiper-pagination"></div>	
                        </div>
                        <div class="category teb_two swiper-container">
                            <ul class="cate_cont swiper-wrapper">
                                <li class="cate_item swiper-slide" data-titSection-color="color-black">
                                    <div class="itemimg">
                                        <img src="/img/main/business_teb4_01.jpg" alt="재무컨설팅">
                                    </div>
                                </li>
                                <li class="cate_item swiper-slide" data-titSection-color="color-white">
                                    <div class="itemimg">
                                        <img src="/img/main/business_teb3_02.jpg" alt="맞춤보장분석">
                                    </div>
                                </li>
                                <li class="cate_item swiper-slide" data-titSection-color="color-black">
                                    <div class="itemimg">
                                        <img src="/img/main/business_teb3_03.jpg" alt="목적자금설계">
                                    </div>
                                </li>
                                <li class="cate_item swiper-slide" data-titSection-color="color-black">
                                    <div class="itemimg">
                                        <img src="/img/main/business_teb3_04.jpg" alt="라이프컨설팅">
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- //개인영업(FA) -->

                    <!-- 보험클리닉 -->
                    <div class="tab_item"> 
                        <div class="tab_inner">
                            <div class="desc">
                                <h2 class="tit_section">OUR <strong>BUS<span>INESS</span></strong></h2>
                                <h4>보험클리닉 </h4>
                                <p class="sub_title">
                                    오프라인 보험샵<br>
                                    원스톱 보험 비교 추천 서비스
                                </p>
                                <p>
                                    요청한 날짜, 시간에 보험샵으로 방문하여<br>
                                    가입한 보험을 전문가에게 분석받고<br>
                                    국내 주요 보험사의 상품을 비교해 최적의 솔루션을 안내해드립니다.
                                </p>
                                <a href="/sales/Otc" class="btn_more"><span>서비스 자세히보기</span><i class="long-arrow-right"></i></a>
                                <a href="/recruit/RecruitBohum" class="btn_more"><span>상담매니저 채용보기</span><i class="long-arrow-right"></i></a>
                            </div>
                            <!-- If we need pagination -->
                            <div class="swiper-pagination"></div>
                        </div>
                        <div class="category teb_one swiper-container">
                            <ul class="cate_cont swiper-wrapper">
                                <li class="cate_item swiper-slide" data-titSection-color="color-white">
                                    <div class="itemimg">
                                        <img src="/img/main/business_teb1_01.jpg" alt="보장분석 제공">
                                    </div>
                                </li>
                                <li class="cate_item swiper-slide" data-titSection-color="color-white">
                                    <div class="itemimg">
                                        <img src="/img/main/business_teb1_04.jpg" alt="숨은 보험금 찾기">
                                    </div>
                                </li>
                                <li class="cate_item swiper-slide" data-titSection-color="color-white">
                                    <div class="itemimg">
                                        <img src="/img/main/business_teb1_03.jpg" alt="보상청구 서비스">
                                    </div>
                                </li>
                                <li class="cate_item swiper-slide" data-titSection-color="color-white">
                                    <div class="itemimg">
                                        <img src="/img/main/business_teb1_02.jpg" alt="보험비교분석 컨설팅">
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- //보험클리닉 -->
                  
                    <!-- TM영업(TFA) -->
                    <div class="tab_item">
                        <div class="tab_inner">
                            <div class="desc">
                                <h2 class="tit_section">OUR <strong>BUS<span>INESS</span></strong></h2>
                                <h4>TM영업(TFA)</h4>
                                <p class="sub_title">
                                    전화상담고객 대상 자동보장분석 시스템 기반<br>
                                    언택트 텔레마케팅 서비스<br>
                                </p>
                                <p>
                                    비대면 텔러마케팅 조직을 운영하여<br>
                                    비교, 설계 부터 관리까지 전문 상담원이 국내 주요 보험사의 상품을<br>
                                    실시간으로 비교해 고객님의 보험을 바로 잡아드립니다.
                                </p>
                                <a href="/sales/Tfa" class="btn_more"><span>서비스 자세히보기</span><i class="long-arrow-right"></i></a>
                            </div>
                            <!-- If we need pagination -->
                            <div class="swiper-pagination"></div>	
                        </div>
                        <div class="category teb_two swiper-container">
                            <ul class="cate_cont swiper-wrapper">
                                <li class="cate_item swiper-slide" data-titSection-color="color-black">
                                    <div class="itemimg">
                                        <img src="/img/main/business_teb5_01.jpg" alt="보험상품비교">
                                    </div>
                                </li>
                                <li class="cate_item swiper-slide" data-titSection-color="color-white">
                                    <div class="itemimg">
                                        <img src="/img/main/business_teb5_02.jpg" alt="보험 리모델링">
                                    </div>
                                </li>
                                <li class="cate_item swiper-slide" data-titSection-color="color-white">
                                    <div class="itemimg">
                                        <img src="/img/main/business_teb5_03.jpg" alt="맞춤상품분석">
                                    </div>
                                </li>
                                <li class="cate_item swiper-slide" data-titSection-color="color-black">
                                    <div class="itemimg">
                                        <img src="/img/main/business_teb5_04.jpg" alt="고객관리서비스">
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- //TM영업(TFA) -->

                </div>
            </div>
        </section>
        <!-- //비즈니스 -->

        <!-- TV CF 광고 -->
        <section class="section section_TVC" id="TVC">
            <div class="inner">
                <h2 class="tit_section">peoplelife <strong>TVC</strong></h2>
                <div class="tvc_cont clearfix">
                    <div class="tvc_show float-left" data-target="#modal_intro_ads" data-title="보험고민" data-video="/file/tvcf_bohum_2020_second_type1_30s.mp4">
                        <i></i>
                        <img src="https://i.ytimg.com/vi/25AY7L-bcwI/maxresdefault.jpg" alt="보험고민편">
                    </div>
                    <div class="tvc_swiper float-left">
                        <div class="tvc_inner">
                            <div class="swiper-container">
                                <ul class="tvc_list swiper-wrapper">
                                    <li class="tvc_item swiper-slide">
                                        <p>TVC광고 &lt;카톡&gt;편</p>
                                    </li>
                                    <li class="tvc_item swiper-slide">
                                        <p>TVC광고 &lt;검색&gt;편</p>
                                    </li>
                                    <li class="tvc_item swiper-slide">
                                        <p>TVC광고 &lt;유튜브&gt;편</p>
                                    </li>
                                    <li class="tvc_item swiper-slide">
                                        <p>TVC광고 &lt;보험고민&gt;편</p>
                                    </li>
                                    <li class="tvc_item swiper-slide">
                                        <p>TVC광고 &lt;실손&gt;편</p>
                                    </li>
                                    <li class="tvc_item swiper-slide">
                                        <p>TVC광고 &lt;일반&gt;편</p>
                                    </li>
                                    <li class="tvc_item swiper-slide">
                                        <p>TVC광고 &lt;나이스&gt;편</p>
                                    </li>
                                    <li class="tvc_item swiper-slide">
                                        <p>TVC광고 &lt;자꾸만 보고 싶네&gt;편</p>
                                    </li>
                                    <li class="tvc_item swiper-slide">
                                        <p>TVC광고 &lt;어쩌다 마주친&gt;편</p>
                                    </li>
                                    <li class="tvc_item swiper-slide">
                                        <p>TVC광고 &lt;여기서 내려요&gt;편</p>
                                    </li>
                                    <li class="tvc_item swiper-slide">
                                        <p>TVC광고 &lt;CEO에게 복&gt;편</p>
                                    </li>
                                    <li class="tvc_item swiper-slide">
                                        <p>TVC광고 &lt;보장&gt;편</p>
                                    </li>
                                    <li class="tvc_item swiper-slide">
                                        <p>TVC광고 &lt;보험료&gt;편</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="tvcPaging">
                        <!-- If we need pagination -->
                        <div class="swiper-pagination"></div>
                        <!-- If we need navigation buttons -->
                        <div class="swiper-button-prev tvcBtn"></div>
                        <div class="swiper-button-next tvcBtn"></div>
                    </div>
                </div>
            </div>
        </section>
        <!-- //TV CF광고  -->


        <!-- 뉴스/매거진 -->
        <section class="section section_about" id="about">
            <div class="inner">
                <h2 class="tit_section"><strong>about</strong> peoplelife</h2>
            </div>
            <div class="inner">
                <div class="about_cont">
                    <div class="webzine_cont float-left">
                        <div class="tit">
                            <p class="name">peoplelife Webzine</p>
                            <p class="date"></p>
                        </div>
                        <div class="webzine_img">
                            <a href="/promotion/Webzine"><img src="" alt="웹진"></a>
                        </div>
                        <a href="/promotion/Webzine" class="btn_more"><span>웹진 보기</span><i class="long-arrow-right"></i></a>
                    </div>
                    <div class="news_cont float-left">
                        <div class="news_swiper">
                            <ul class="news_list">
                                <li class="news_item" data-attr-top="topView" data-attr-id="id">
                                    <a href="">
                                        <div class="desc">
                                            <div class="news_category" data-text="/category2"></div>
                                            <div class="news_tit" data-text="/title" data-clamp="2"></div>
                                            <div class="news_date" data-text="/writeDate"></div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                            <div class="newsPaging">
                                <!-- If we need navigation buttons -->
                                <div class="swiper-button-prev newsBtn"></div>
                                <div class="swiper-button-next newsBtn"></div>
                            </div>
                        </div>
                        <a href="/promotion/news" class="btn_more"><span>피플라이프 뉴스</span><i class="long-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </section>
        <!-- //뉴스/매거진 -->


        <!-- 채용 -->
        <section class="section section_recruit" id="recruit">
            <div class="inner">
                <h2 class="tit_section"><strong>recruit</strong> peoplelife</h2>
                <div class="recruit_cont">
                    <h3 class="tit_sub">피플라이프와 함께 금융 전문가로 거듭날 인재를 기다립니다</h3>
                    <ul class="recruit_list">
                        <li class="recruit_corp">
                            <a href="/recruit/RecruitCorp">법인컨설팅 채용</a>
                            <i class="deadline_label">채용종료</i>
                        </li>
                        <li class="recruit_fa">
                            <a href="/recruit/RecruitFa">개인영업(FA) 채용</a>
                            <i class="deadline_label">채용종료</i>
                        </li>
                        <li class="recruit_insurance"> <!--.close -->
                            <a href="/recruit/RecruitBohum">보험클리닉 </a>
                            <i class="deadline_label">채용종료</i>
                        </li>
                        <!-- <li class="recruit_efa close">
                            <a href="/recruit/RecruitEfa">찾아가는<br>보험클리닉(Multi)</a>
                            <i class="deadline_label">채용종료</i>
                        </li> -->
                    </ul>
                    <div class="btn_area">
                        <button class="btn_more" type="button"><span>채용정보</span><i class="long-arrow-right"></i></button>
                    </div>
                </div>
            </div>
        </section>
        <!-- //채용 -->
    </main>
</template>



<script>
    export default {}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/main/main.scss';
</style>